@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"
@import "tailwindcss/screens"
@import '~@fortawesome/fontawesome-free/scss/fontawesome'
@import '~@fortawesome/fontawesome-free/scss/solid'
@import '~@fortawesome/fontawesome-free/scss/brands'
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap')
@import '~glightbox/dist/css/glightbox.css'
@import 'vue-select/dist/vue-select.css'
@import 'floating-vue/dist/style.css'
@import './page'

body
    font-family: 'Nunito', sans-serif
    font-size: 1rem
    color: #242C33
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

    #mobile-menu
        #mobile-menu-panel
            justify-content: flex-end

        &.has-background
            background-color: #181E24
            position: fixed

        &.active
            position: fixed
            height: 100vh
            background-color: #181E24

            #mobile-menu-panel
                justify-content: space-between

            .logo
                display: block

            #mobile-menu-bars
                display: none

            #mobile-menu-times
                display: inline-flex

            #mobile-menu-wrapper
                display: flex

    .prose
        max-width: 100%

    .font-secondary
        font-family: 'Montserrat', sans-serif

    .width-90p
        width: 90%

    .hero-label
        &.hero-1
            @apply transition-colors
            background-color: rgba(21, 27, 33, 0.73)

            &:hover
                background-color: rgba(21, 27, 33, 1)

        &.hero-2
            @apply transition-colors
            background-color: rgba(33, 41, 49, 0.73)

            &:hover
                background-color: rgba(33, 41, 49, 1)

        &.hero-3
            @apply transition-colors
            background-color: rgba(44, 54, 64, 0.73)

            &:hover
                background-color: rgba(44, 54, 64, 1)

        &.hero-4
            @apply transition-colors
            background-color: rgba(65, 74, 83, 0.73)

            &:hover
                background-color: rgba(65, 74, 83, 1)

    .delivery-description span
        color: rgb(107, 114, 128) !important

    .active-delivery, .active-payment
        border-width: 2px
        border-color: rgb(202, 9, 15)

.wp-block-columns
    display: flex
    flex-direction: column

    .wp-block-column
        flex: 1
        margin: 0 1rem

.has-text-align-center
    text-align: center

.prose .has-vivid-red-color
    color: rgb(207, 46, 46)

.prose .has-pale-pink-color
    color: rgb(247, 141, 167)

.prose .has-luminous-vivid-orange-color
    color: rgb(255, 105, 0)

.prose .has-luminous-vivid-amber-color
    color: rgb(252, 185, 0)

.prose .has-light-green-cyan-color
    color: rgb(123, 220, 181)

.prose .has-luminous-vivid-amber-color
    color: rgb(252, 185, 0)

.prose .has-vivid-green-cyan-color
    color: rgb(0, 208, 132)

.prose .has-pale-cyan-blue-color
    color: rgb(142, 209, 252)

.prose .has-vivid-cyan-blue-color
    color: rgb(6, 147, 227)

.prose .has-vivid-purple-color
    color: rgb(155, 81, 224)

.prose .has-cyan-bluish-gray-color
    color: rgb(171, 184, 195)

.prose .has-small-font-size
    font-size: 13px

.prose .has-normal-font-size
    font-size: 16px

.prose .has-medium-font-size
    font-size: 20px

.prose .has-large-font-size
    font-size: 36px

.prose .has-huge-font-size
    font-size: 48px

.prose a
    color: rgb(207, 46, 46)

    &.underline-0, &.bg-red
        @apply no-underline

    &.bg-red
        @apply text-white

.prose .wp-block-embed__wrapper
    display: flex
    justify-content: center

.aligncenter
    display: flex
    justify-content: center

.prose .wp-block-media-text
    .wp-block-media-text__content
        display: flex
        justify-content: center
        flex-direction: column

    p
        word-break: break-word

.prose .wp-block-button
    margin: 10px 0

.prose .wp-block-button__link, .prose .wp-block-button__link strong
    color: white

@media (min-width: 1024px)
    .wp-block-columns
        flex-direction: row

    .prose .wp-block-media-text
        display: grid
        grid-gap: 2rem
        grid-template-columns: 50% 1fr

        &.has-media-on-the-right
            .wp-block-media-text__media
                order: 2

        &.is-vertically-aligned-top
            .wp-block-media-text__content
                justify-content: start

        &.is-vertically-aligned-bottom
            .wp-block-media-text__content
                justify-content: end

.pulse
    display: block
    border: 2px rgba(255, 0, 0, 0) dashed
    animation: border-pulsate 1s infinite

@keyframes border-pulsate
    0%
        border-color: rgba(255, 0, 0, 1)
    50%
        border-color: rgba(255, 0, 0, 0)
    100%
        border-color: rgba(255, 0, 0, 1)

.blog-item-wrapper
    & > img
        transition: 5s cubic-bezier(0.03, 0.38, 0, 0.72)

    &:hover
        & > img
            transform: scale(1.1)

.loader
    @apply absolute border-solid border-red rounded-full w-9 h-9
    border-width: 3px

    &.animate
        animation: fill linear 5s

@keyframes fill
    0%
        clip-path: polygon(50% -20.71%, 50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)

    12.5%
        clip-path: polygon(50% -20.71%, 50% 50%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)

    25%
        clip-path: polygon(50% -20.71%, 50% 50%, 120.71% 50%, 120.71% 50%, 120.71% 50%, 120.71% 50%, 100% 0%)

    37.5%
        clip-path: polygon(50% -20.71%, 50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 0%)

    50%
        clip-path: polygon(50% -20.71%, 50% 50%, 50% 120.71%, 50% 120.71%, 50% 120.71%, 100% 100%, 100% 0%)

    62.5%
        clip-path: polygon(50% -20.71%, 50% 50%, 0% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%)

    75%
        clip-path: polygon(50% -20.71%, 50% 50%, -20.71% 50%, -20.71% 50%, 0% 100%, 100% 100%, 100% 0%)

    87.5%
        clip-path: polygon(50% -20.71%, 50% 50%, 0% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%)

    100%
        clip-path: polygon(50% -20.71%, 50% 50%, 50% -20.71%, 0% 0%, 0% 100%, 100% 100%, 100% 0%)

.blog-big-product
    a
        text-decoration: none

.blog-product-list
    @apply grid gap-4 grid-cols-1

.blog-small-product
    img
        @apply m-0

    h3
        @apply mt-2 font-black text-center mb-0

    a.title
        @apply flex flex-grow
        text-decoration: none

    p
        @apply my-2

    .buy-button
        @apply w-full bg-black rounded-full mt-2 py-2 px-4 text-sm font-bold text-white hover:bg-gray transition

    .rent-button
        @apply w-full bg-red rounded-full mt-2 py-2 px-4 text-sm font-bold text-white hover:bg-red-dark transition

    .info-button
        @apply w-full bg-gray-200 rounded-full mt-2 py-2 px-4 text-sm font-bold text-black hover:bg-gray-300 transition

@screen md
    .blog-product-list
        @apply grid-cols-2

@screen lg
    .blog-product-list
        @apply grid-cols-4

.tooltip-area
    min-width: 225px

label.required:after
    content: ' *'
    @apply text-red

.voucher-code-wrapper
    @apply grid
    grid-template-columns: 1fr auto
    align-items: flex-end

.vs__dropdown-toggle
    @apply flex appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500

.vs--disabled
    .vs__dropdown-toggle, .vs__search
        @apply bg-gray-200 border border-gray-200 text-gray-700

.mini-logo
    width: 100%
    height: 100%
    max-width: 235px
    max-height: 127px

.features-table
    width: 100%
    border-collapse: collapse
    border: 1px solid #cccccc
    border-radius: 10px
    margin: 10px 0

    td
        width: 50%
        padding: 5px 10px
        border: 1px solid #cccccc

        &.name
            background-color: #ececec
            font-weight: bold

#cm.box
    position: fixed !important
    left: 50% !important
    top: 45% !important
    height: fit-content !important
    transform: translate(-50%, -50%) !important
    max-width: 700px !important

@media only screen and (max-width: 768px)
    #cm.box
        min-width: 90% !important

.v-popper--theme-tooltip
    .v-popper__inner
        @apply bg-white border border-solid border-white-dark shadow-xl text-gray text-sm