.contacts
    font-size: 0.875em
    line-height: 1.7142857

    p
        margin-top: 1.25em
        margin-bottom: 1.25em

    hr
        border-color: #e5e7eb
        border-top-width: 1px
        margin-top: 3em
        margin-bottom: 3em

    ul
        margin-top: 1.25em
        margin-bottom: 1.25em

    li
        margin-top: 0.5em
        margin-bottom: 0.5em

    ul > li
        position: relative
        padding-left: 1.75em

    ul > li::before
        content: ""
        position: absolute
        background-color: #d1d5db
        border-radius: 50%
        width: 0.375em
        height: 0.375em
        top: calc(0.875em - 0.1875em)
        left: 0.25em

    h1
        color: #111827
        font-weight: 800
        font-size: 2.25em
        margin-top: 0
        margin-bottom: 0.8888889em

    h2
        color: #111827
        font-weight: 700
        font-size: 1.5em
        margin-top: 2em
        margin-bottom: 1em
        line-height: 1.3333333

    h3
        color: #111827
        font-weight: 600
        font-size: 1.25em
        margin-top: 1.6em
        margin-bottom: 0.6em
        line-height: 1.6

    h4
        color: #111827
        font-weight: 600
        margin-top: 1.5em
        margin-bottom: 0.5em
        line-height: 1.5

    table
        width: 100%

    thead th
        font-size: 15px
        background: rgb(54, 61, 68)
        color: #fff

    tbody tr:last-child td
        border-bottom-width: 0

    tbody td
        border-bottom-width: 1px
        border-bottom-color: #e5e7eb


@media (max-width: 600px)
    .contacts table tr td
        width: 100% !important
        display: block !important
        border-radius: 0 !important
        overflow: hidden
